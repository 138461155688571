
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import AppButton from '@/components/atoms/AppButton.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import { getDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterTop',
  components: {
    AppButton,
    DssIcon,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const disabledFlag = ref(true)
    const getList = async () => {
      const url = `${BASE_URL}/outer/item/sent/history/check`
      const response = await getDataCredential(url)
      disabledFlag.value = response.status !== 200
    }
    getList()

    return {
      router,
      disabledFlag,
    }
  },
})
